@font-face {
  font-family: 'dice';
  src: url('https://c92.me/img/dice.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.app {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 60px; /* Height of your header */
  padding-bottom: 50px; /* Height of your footer */
}

.tabs {
  display: flex;
  justify-content: space-evenly;
  background: linear-gradient(
    180deg,
    rgb(217, 39, 29) 0%,
    rgb(195, 27, 50) 100%
  );
  padding: 10px;
}

.tab {
  background: none;
  border: none;
  padding: 10px;
  font-weight: bold;
  color: #333;
}

.tab i {
  margin-right: 8px;
}

.tab:focus {
  outline: none;
}

.offer-wall {
  overflow-y: auto; /* Makes this area scrollable */
  flex-grow: 1; /* Allows this area to fill the space between header and footer */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.offer {
  display: flex;
  align-items: center; /* Align the children (image and details) vertically */
  justify-content: space-between; /* Space between the items and the button */
  width: 95%;
  padding: 10px;
  background: rgb(255, 255, 255);
  border: 1px solid #eeececb4;
}

.offer img {
  width: 50px; /* Or the size you want */
  height: 50px; /* Or the size you want */
  margin-right: 20px; /* Space between the image and the text */
  border-radius: 8px;
}

.offer-details {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: flex-start; /* Align text to the start (left) */
  flex-grow: 1; /* Take up remaining space */
  max-width: 100%;
}

.offer-name {
  font-size: 0.8em;
}
.offer-details p {
  text-align: left; /* Align text to the left */
  margin: 0; /* Remove default margins */
  padding: 0; /* Ensure no padding is affecting layout */
}

.offer-details p:first-child {
  font-weight: bold; /* Make the offer name bold */
  margin-bottom: 5px; /* Space between offer name and description */
}

.offer-description {
  flex: 1;
  max-width: 75%;
  font-size: 0.6em;
}

.coin-button {
  background-color: green;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 50px; /* Fixed width */
  height: 40px;
  display: flex; /* Use flexbox for positioning icon and text */
  align-items: center; /* Center the icon and text vertically */
  justify-content: center; /* Center the icon and text horizontally */
  padding: 10px; /* Padding for the button */
  min-width: 75px;
  position: relative;
}

.coin-button:before {
  content: 'FREE';
  position: absolute;
  right: 100%; /* Position 'FREE' to the left of the button */
  margin-right: 10px; /* Space between 'FREE' and the button */
  font-weight: bold;
  color: #008000; /* Adjust the color if necessary */
}

.coin-button i {
  margin-right: 5px;
}

.coin-button:focus {
  outline: none;
}

.tabs {
  display: flex;
  justify-content: space-around; /* Distribute tabs evenly */
  padding: 0px 0; /* Add some vertical padding */
}

.tab {
  flex-grow: 1; /* Each tab should grow to fill available space */
  display: flex; /* Use flexbox for centering content */
  align-items: center; /* Vertical alignment */
  justify-content: center; /* Horizontal alignment */
  background: none;
  border: none;
  padding: 15px 10px; /* Increase padding for a larger tap area */
  font-size: 1em; /* Adjust font size as needed */
  color: #fff;
  transition: background-color 0.3s, color 0.3s; /* Smooth transitions for color and background */
  width: 50%;
}

.tab:focus {
  outline: none; /* Removes the outline to keep the design clean */
}

.tab-active {
  background-color: #007bff; /* Active tab color */
  color: white; /* Text color for the active tab */
}

.tab:active {
  opacity: 0.8; /* Slight opacity change for a touch feedback */
}

body {
  background-image: url('https://c92.me/img/back.png'); /* Path to your image */
  background-repeat: repeat; /* This will tile your image */
  background-position: top left; /* This sets the starting position of the image */
  background-attachment: fixed; /* This ensures the image stays in place while scrolling */
  margin: 0px;
}

.app-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100; /* Keeps the footer above other elements */
  background-color: #fff; /* A neutral color for the footer */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background: linear-gradient(
    180deg,
    rgb(217, 39, 29) 0%,
    rgb(195, 27, 50) 100%
  );
  box-shadow: 0px 0px 5px 4px rgba(0, 0, 0, 0.49);
  position: fixed; /* Fix the header at the top */
  top: 0; /* Align the header to the top edge */
  left: 0; /* Align the header to the left edge */
  right: 0; /* Ensure the header extends to the right edge */
  z-index: 1000; /* Ensure the header stays on top of other content */
  height: 60px;
  font-family: dice;
  color: #fff;
  border-bottom: 1px solid #e64442;
}

.left_box {
  width: 33.3%;
}

.right_box {
  width: 33.3%;
  align-items: flex-start;
}

.giftbox {
  width: 50px; /* Adjust as needed */
  height: 50px;
  -webkit-animation: wobble-hor-bottom 0.8s infinite both;
  animation: wobble-hor-bottom 0.8s infinite both;
}

.header-center {
  font-size: 50px;
  color: white;
  text-shadow: 2px 2px 4px #000000;
  -webkit-animation: jello-horizontal 0.9s both;
  animation: jello-horizontal 0.9s both;
}

.user-info {
  padding: 10px;
  background-color: rgb(199, 37, 29);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.75) inset;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75) inset;
  -moz-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.75) inset;
  border: 1px solid #e64442;
  border-radius: 5px;
  width: fit-content;
}

.user-coins {
  padding: 10px;
  background-color: rgb(199, 37, 29);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.75) inset;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75) inset;
  -moz-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.75) inset;
  border: 1px solid #e64442;
  border-radius: 5px;
  width: fit-content;
  float: right;
}

@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(0px) rotate(-6deg);
    transform: translateX(0px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(1px) rotate(6deg);
    transform: translateX(1px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(0px) rotate(-3.6deg);
    transform: translateX(0px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(1px) rotate(2.4deg);
    transform: translateX(1px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(0px) rotate(-1.2deg);
    transform: translateX(0px) rotate(-1.2deg);
  }
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000; /* Higher z-index */
}

.popup {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 400px;
  margin: 0; /* Remove auto margin */
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.close-btn {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
}

@media (max-width: 600px) {
  .popup {
    width: 90%;
    padding: 15px;
  }
}
body,
html {
  overscroll-behavior: none;
}

.custom-countdown {
  display: none;
}
